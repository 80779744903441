import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { requestSignUpStart } from "@remotes/requestSignUpStart";
import { isValidPhoneNumber } from "@utils/validation";
import { ErrorMessage } from "@components/ErrorMessage";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { showSuccessMsg } from "@utils/notification";

const PhoneNumber = () => {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError, setError] = useErrorHandling();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const unmaskedNumber = input.replace(/[^\d]/g, ""); // Strip all non-digit characters
    setPhoneNumber(unmaskedNumber); // Keep unmasked value in state
    clearError();
  };

  const handleNextClick = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      return setError("Please enter a valid phone number.");
    }
    clearError();
    startLoading();

    try {
      const { status } = await requestSignUpStart({ phonenumber: phoneNumber });
      if (status === "exists") {
        setError("This phone number is already in use.");
        return;
      }

      showSuccessMsg("Otp sent successfully!");
      navigate(`/sign-up/verify-otp?phone=${phoneNumber}`);
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>What's your phone?</SignInUpTitle>
      <Spacing size={100} />
      <TextField
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        mask="+{1} (000) 000-0000" // US phone number mask
      />
      <ErrorMessage text={error} />
      <Spacing size={46} />
      <Button
        disabled={phoneNumber.toString().length < 1 || error != null}
        loading={loading}
        onClick={handleNextClick}
      >
        Next
      </Button>
      <Spacing size={600} />
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default PhoneNumber;
