// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
// import { PlanCard } from "./components/PlanCard";
// import { Plan } from "./constants";
import GoogleLoginButton from "@components/GoogleLogin";
import { useAuth } from "@contexts/AuthContext";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { useLoading } from "@hooks/useLoading";

const SignUp = () => {
  const navigate = useNavigate();
  const { loginWithGoogle } = useAuth();

  const [, startLoading, stopLoading] = useLoading();
  const [, handleError] = useErrorHandling();
  // const [plan, setPlan] = useState<Plan>("BASIC");

  /**
   * Navigates to /sign-up/email-address when the CTA is clicked.
   */
  const handleCTAClick = () => {
    navigate("/sign-up/email-address");
  };

  const handlePhoneCTAClick = () => {
    navigate("/sign-up/phone-number");
  };

  const handleLogInBtnClick = () => {
    navigate("/login");
  };

  /**
   * Signs up a user with Google and navigates to /style-library upon success.
   *
   * @param {string} code - The authorization code returned from the Google OAuth flow.
   */
  const googleSignUp = async (code: string) => {
    try {
      startLoading();

      await loginWithGoogle(code);
      navigate("/style-library");
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>Sign up for Wand</SignInUpTitle>
      <Spacing size={56} />
      {/* <PlanCard
        plan="BASIC"
        price="1-week free trial"
        rowTexts={[
          "1 week free than $9.99/month",
          "Generate 500 images per month",
          "Create 10 styles per month",
        ]}
        selected={plan === "BASIC"}
        onClick={() => setPlan("BASIC")}
      />
      <Spacing size={8} />
      <PlanCard
        plan="PRO"
        price="$39.99/mo"
        rowTexts={[
          "Generate 5,000 images per month",
          "Create 30 styles per month",
        ]}
        selected={plan === "PRO"}
        onClick={() => setPlan("PRO")}
      /> */}
      <GoogleLoginButton
        onSuccess={(code) => {
          googleSignUp(code);
        }}
        text="signup_with"
      />

      <Spacing size={16} />
      <CredentialSignupButton onClick={handleCTAClick}>
        <svg
          className="credential-signup-icon"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
            stroke="#000"
            stroke-width="1.5"
          />
          <path d="M20 8L12 13L4 8" stroke="#000" stroke-width="1.5" />
        </svg>
        <span>Sign up with Email</span>
      </CredentialSignupButton>
      {/* <p className={priceInstructionStyle}>
        Free for 1 week, than $9.99 a month.
        <br />
        You can cancel anytime.
      </p> */}
      <Spacing size={16} />
      <CredentialSignupButton onClick={handlePhoneCTAClick}>
        <svg
          className="credential-signup-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          width="22"
          height="22"
        >
          <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
          <line x1="12" y1="18" x2="12" y2="18"></line>
        </svg>
        <span>Sign up with Phone</span>
      </CredentialSignupButton>
      <Spacing size={48} />
      <Border />
      <Spacing size={32} />
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <p
          className={css`
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #aaaaaa;
            margin-right: 5px;
            margin-top: 2px;
          `}
        >
          Already have an account?
        </p>
        <TextButton onClick={handleLogInBtnClick}>Log in</TextButton>
      </div>
      <Spacing size={280} />
    </div>
  );
};

const Border = styled.div`
  width: 326px;
  height: 1px;
  background: #343434;
`;

const TextButton = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
`;

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 0 0;
`;

// const priceInstructionStyle = css`
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 15px;
//   text-align: center;
//   color: #aaaaaa80;
// `;

const CredentialSignupButton = styled.button`
  /* Basic Button Styling */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #333;
  border: 1px solid #dadce0;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: sans-serif;
  text-align: center;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease;
  outline: none;

  /* Focus outline */
  &:focus {
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.3);
    border: 1px solid rgba(66, 133, 244, 1);
  }

  &:hover {
    background-color: #f8f9fa;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
      0 1px 3px 1px rgba(60, 64, 67, 0.149);
  }

  /* Disabled State */
  &:disabled {
    background-color: #e9e9e9;
    color: #b1b1b1;
    border-color: #d3d3d3;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: #e9e9e9;
      border-color: #d3d3d3;
      box-shadow: none;
    }
  }

  span {
    display: block;
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ... when text overflows */
  }

  /* Styling for the SVG Email Icon */
  .credential-signup-icon {
    margin-right: 8px; /* Spacing between icon and text */
  }

  /* Mobile Responsiveness */
  @media (max-width: 600px) {
    font-size: 13px;
    padding: 8px 12px;
    .email-icon {
      width: 14px;
      height: 14px;
    }
  }
`;

export default SignUp;
