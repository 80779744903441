import { http } from ".";

type SignUpParam = { email: string } | { phonenumber: string }; // Modified to string since phone numbers will come from query params

export async function requestSignUp(
  param: SignUpParam,
  password: string,
  username: string
) {
  if ("email" in param) {
    return http.post("/signup/", { email: param.email, password, username });
  } else if ("phonenumber" in param) {
    return http.post("/signup/", {
      phonenumber: param.phonenumber,
      password,
      username,
    });
  } else {
    throw new Error(
      "Invalid input parameter. Please provide either an email or a phone number."
    );
  }
}
