import { toast } from "react-toastify";

const toastConfig = {
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

/**
 * Show Success Message
 * @param msg
 */
export const showSuccessMsg = (msg: string): void => {
  toast.success(msg, toastConfig);
};
