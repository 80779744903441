// AuthContext.tsx

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { requestLogin } from "@remotes/requestLogin";
import { getTokens, removeTokens, setTokens } from "@utils/authStorage";
import { useLoading } from "@hooks/useLoading";
import { refreshAccessToken } from "@remotes/refreshAccessToken";
import { useLocation } from "react-router-dom";
import { requestGoogleAuth } from "@remotes/requestGoogleAuth";
import { requestSignUp } from "@remotes/requestSignUp";

type AuthContextType = {
  isAuthenticated: boolean;
  login: (params: LoginParams) => void;
  logout: () => void;
  loading: boolean;
  loginWithGoogle: (code: string) => void;
  signUp: (params: LoginParams) => void;
};

type LoginParams = {
  email?: string;
  username?: string;
  password: string;
  phonenumber?: string;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const excludedPaths = [
  "/sign-up",
  "/sign-up/email-address",
  "/sign-up/verify-otp",
  "/sign-up/create-account",
  "/login",
];

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, , stopLoading] = useLoading(true);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      if (excludedPaths.includes(location.pathname)) {
        return stopLoading();
      }

      const { refreshToken } = getTokens();
      if (refreshToken == null) {
        return stopLoading();
      }

      const accessToken = await refreshAccessToken(refreshToken);
      if (accessToken == null) {
        return stopLoading();
      }

      setTokens(accessToken, refreshToken);
      setIsAuthenticated(true);
      stopLoading();
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopLoading]);

  /**
   * Authenticates a user using either email or username along with a password.
   * Sets authentication tokens upon successful login and updates the authentication state.
   *
   * @param {LoginParams} params - The login parameters.
   * @param {string} [params.email] - The email of the user (optional).
   * @param {string} [params.username] - The username of the user (optional).
   * @param {string} params.password - The password of the user.
   *
   * @throws Will throw an error if neither email nor username is provided.
   */
  const login = async ({ email, username, password }: LoginParams) => {
    if (!email && !username) {
      throw new Error("Email or username is required.");
    }

    const payload = email ? { email, password } : { username, password };

    const { token, refresh } = await requestLogin(payload);

    setTokens(token, refresh);
    setIsAuthenticated(true);
  };

  /**
   * Authenticates a user using the Google Id token returned from the Google OAuth process.
   * Sets authentication tokens upon successful login and updates the authentication state.
   *
   * @param {string} idToken - The Google Id token returned from the Google OAuth process.
   *
   * @throws Will throw an error if the idToken is invalid.
   */
  const loginWithGoogle = async (idToken: string) => {
    if (!idToken) {
      throw new Error("Invalid Id provided.");
    }

    const data: any = await requestGoogleAuth(idToken);
    setTokens(data?.token, data?.refresh);
    setIsAuthenticated(true);
  };

  /**
   * Creates a new user with the provided email and/or username and password.
   * Sets authentication tokens upon successful sign up and updates the authentication state.
   *
   * @param {LoginParams} params - The sign up parameters.
   * @param {string} [params.email] - The email of the user (optional).
   * @param {string} [params.username] - The username of the user (optional).
   * @param {string} params.password - The password of the user.
   * @param {string} [params.phonenumber]
   *
   * @throws Will throw an error if neither email nor username is provided.
   */
  const signUp = async ({
    email,
    username,
    password,
    phonenumber,
  }: LoginParams) => {
    if (!email && !username && !phonenumber) {
      throw new Error("Email, phone or username is required.");
    }

    let param: { email: string } | { phonenumber: string } | undefined;

    if (email) {
      param = { email };
    } else if (phonenumber) {
      param = { phonenumber };
    }

    if (!param) {
      throw new Error("Email or phone is required.");
    }

    const { token, refresh } = await requestSignUp(
      param,
      password,
      username || ""
    );

    setTokens(token, refresh);
    setIsAuthenticated(true);
  };

  const logout = () => {
    const result = window.confirm("Are you sure you want to log out?");
    if (!result) {
      return;
    }

    removeTokens();
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        loading,
        loginWithGoogle,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
