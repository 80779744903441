import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { requestSignUpStart } from "@remotes/requestSignUpStart";
import { isValidEmail } from "@utils/validation";
import { ErrorMessage } from "@components/ErrorMessage";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { showSuccessMsg } from "@utils/notification";

const EmailAddress = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError, setError] = useErrorHandling();

  const handleEmailChange = (value: string) => {
    setEmail(value);
    clearError();
  };

  /**
   * Handle the next button click. Validate the email address, and if
   * valid, call the requestSignUpStart method and navigate to the
   * verify email page if the response status is not 'exists'.
   *
   * @async
   * @function
   */
  const handleNextClick = async () => {
    if (!isValidEmail(email)) {
      return setError("Please enter a valid email address.");
    }
    clearError();
    // TODO: Handle already exists account
    startLoading();
    try {
      const { status } = await requestSignUpStart({ email });
      if (status === "exists") {
        setError("This email address is already in use.");
        return;
      }
      showSuccessMsg("Verification email sent successfully!");
      navigate(`/sign-up/verify-otp?email=${email}`);
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>What's your email?</SignInUpTitle>
      <Spacing size={100} />
      <TextField
        placeholder="Email"
        value={email}
        onChange={(e) => handleEmailChange(e.target.value)}
      />
      <ErrorMessage text={error} />
      <Spacing size={46} />
      <Button
        disabled={email.length < 1 || error != null}
        loading={loading}
        onClick={handleNextClick}
      >
        Next
      </Button>
      <Spacing size={600} />
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default EmailAddress;
