import { http } from ".";

type VerificationParam = { email: string } | { phonenumber: number };

export async function requestVerificationCheck(
  code: string,
  param: VerificationParam
) {
  if ("email" in param) {
    return http.post("/verificationcheck/", { code, email: param.email });
  } else if ("phonenumber" in param) {
    return http.post("/verificationcheck/", {
      code,
      phonenumber: param.phonenumber,
    });
  } else {
    throw new Error(
      "Invalid input parameter. Please provide either an email or phone number."
    );
  }
}
