import { http } from ".";

// Define a type for either email or phone number
type SignUpParam = { email: string } | { phonenumber: string };

export async function requestSignUpStart(param: SignUpParam) {
  // Check if the param is an email or phone number
  if ("email" in param) {
    return http.post("/onboard/", { email: param.email });
  } else if ("phonenumber" in param) {
    return http.post("/onboard/", { phonenumber: param.phonenumber });
  } else {
    throw new Error(
      "Invalid input parameter. Please provide either an email or phone number."
    );
  }
}
