import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { TextField } from "@design-system/TextField";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { ErrorMessage } from "@components/ErrorMessage";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { useAuth } from "@contexts/AuthContext";
import { showSuccessMsg } from "@utils/notification";

type SignUpParam = { email: string } | { phonenumber: string };

const CreateAccount = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [signUpParam, setSignUpParam] = useState<SignUpParam | null>(null);
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError] = useErrorHandling();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromQuery = queryParams.get("email");
    const phonenumberFromQuery = queryParams.get("phone");

    if (emailFromQuery) {
      setSignUpParam({ email: emailFromQuery });
      return;
    }
    if (phonenumberFromQuery) {
      setSignUpParam({ phonenumber: phonenumberFromQuery });
      return;
    }
  }, [location.search]);

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    clearError();
  };

  const handleUsernameChange = (value: string) => {
    setUsername(value);
    clearError();
  };

  /**
   * Handle the submission of the form. Validate the email address, and if
   * valid, call the signUp method from the AuthContext, navigate to the
   * style library and show a success message.
   *
   * @async
   * @function
   */
  const handleSubmit = async () => {
    if (signUpParam == null) {
      return;
    }

    try {
      startLoading();
      await signUp({ username, password, ...signUpParam });
      showSuccessMsg("Account created successfully!");
      navigate("/style-library");
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  const isButtonDisabled =
    username.length < 1 || password.length < 1 || error != null;

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>
        Set your username <br /> and password
      </SignInUpTitle>
      <Spacing size={100} />
      <TextField
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => handleUsernameChange(e.target.value)}
        name="username"
        autoComplete="username"
      />
      <Spacing size={12} />
      <TextField
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => handlePasswordChange(e.target.value)}
        name="password"
      />
      <ErrorMessage text={error} />
      <Spacing size={26} />
      <Button
        disabled={isButtonDisabled}
        loading={loading}
        onClick={handleSubmit}
      >
        Continue
      </Button>
      <Spacing size={200} />
    </div>
  );
};

const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default CreateAccount;
