export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (
  phoneNumber: string,
  countryCodeOptional: boolean = true
): boolean => {
  const cleanedNumber = phoneNumber.replace(/[-()\s]/g, "");

  let pattern;

  if (countryCodeOptional) {
    pattern = /^(?:\+?\d{1,3})?\d{7,15}$/;
  } else {
    pattern = /^\+\d{1,3}\d{7,15}$/;
  }

  return pattern.test(cleanedNumber);
};
