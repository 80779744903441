import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/css";
import { Button } from "@design-system/Button";
import { SignInUpTitle } from "@components/SignInUpTitle";
import { Spacing } from "@components/Spacing";
import { ErrorMessage } from "@components/ErrorMessage";
import { requestVerificationCheck } from "@remotes/requestVerificationCheck";
import { useLoading } from "@hooks/useLoading";
import { useErrorHandling } from "@hooks/useErrorHandling";
import { Link } from "react-router-dom";
import { requestSignUpStart } from "@remotes/requestSignUpStart";
import { showSuccessMsg } from "@utils/notification";

type VerificationParam = { email: string } | { phonenumber: number };

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [verificationParam, setVerificationParam] =
    useState<VerificationParam | null>(null);
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, handleError, clearError] = useErrorHandling();

  const inputRefs = useRef<Array<HTMLInputElement | null>>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromQuery = queryParams.get("email");
    const phonenumberFromQuery = queryParams.get("phone");

    if (emailFromQuery) {
      setVerificationParam({ email: emailFromQuery });
      return;
    }
    if (phonenumberFromQuery) {
      setVerificationParam({ phonenumber: Number(phonenumberFromQuery) });
      return;
    }
  }, [location.search]);

  /**
   * Handle a change in the OTP input field at the given index.
   *
   * @param {number} index The index of the OTP input field that changed.
   * @param {string} value The new value of the OTP input field.
   */
  const handleOtpChange = (index: number, value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    clearError();

    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  /**
   * Handles the paste event from the user. If the pasted text is a valid 6 digit OTP,
   * then it will be accepted and the OTP input fields will be populated accordingly.
   * The last input field will be focused if all the input fields are populated.
   * @param e The React.ClipboardEvent triggered by the paste event.
   */
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");

    if (/^\d{6}$/.test(pastedText)) {
      const newOtp = pastedText.split("");
      setOtp(newOtp);

      if (inputRefs.current[5] && newOtp[5] !== "") {
        inputRefs.current[5]?.focus();
      }

      clearError();
    }
  };

  /**
   * Handles the keydown event on the OTP input fields. If the Backspace key is pressed and
   * the current input field is empty and not the first one, then the focus will be shifted
   * to the previous input field.
   * @param index The index of the current input field.
   * @param e The React.KeyboardEvent triggered by the keydown event.
   */
  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  /**
   * Handles the confirm button click. Verifies the OTP and redirects to create account page
   * if successful.
   */
  const handleConfirm = async () => {
    if (verificationParam == null) {
      return;
    }

    const code = otp.join("");

    startLoading();
    try {
      await requestVerificationCheck(code, verificationParam);
      if ("email" in verificationParam) {
        navigate(`/sign-up/create-account?email=${verificationParam.email}`);
      } else if ("phonenumber" in verificationParam) {
        navigate(
          `/sign-up/create-account?phone=${verificationParam.phonenumber}`
        );
      }
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };
  const isConfirmButtonEnabled =
    otp.every((digit) => digit !== "") && error == null;

  const handleResendOtp = async () => {
    if (!verificationParam) return;

    startLoading();
    try {
      if ("email" in verificationParam) {
        await requestSignUpStart({ email: verificationParam.email });
        navigate(`/sign-up/verify-otp?email=${verificationParam.email}`);
      } else if ("phonenumber" in verificationParam) {
        await requestSignUpStart({
          phonenumber: String(verificationParam.phonenumber),
        });
        navigate(`/sign-up/verify-otp?phone=${verificationParam.phonenumber}`);
      }
      showSuccessMsg("Otp sent successfully!");
    } catch (e) {
      handleError(e);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className={wrapperStyle}>
      <SignInUpTitle>Enter your verification code</SignInUpTitle>
      <div className={descStyle}>
        We sent a code to your{" "}
        {verificationParam && "email" in verificationParam
          ? `email`
          : "phone number"}
        !
      </div>
      <Spacing size={100} />
      <div className={otpContainerStyle}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            className={otpBoxStyle}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
        <div className={resendOtpStyle}>
          <Link to="#" onClick={handleResendOtp}>
            Resend OTP
          </Link>
        </div>
      </div>
      <ErrorMessage text={error} />
      <Spacing size={22} />
      <Button
        disabled={!isConfirmButtonEnabled}
        loading={loading}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
      <Spacing size={600} />
    </div>
  );
};
const descStyle = css`
  font-size: 1rem;
  color: #ccc;
  margin-top: 10px;
`;
const otpContainerStyle = css`
  display: flex;
  gap: 10px;
  justify-content: center;
  width: fit-content;
  max-width: 90%;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const otpBoxStyle = css`
  width: 45px;
  height: 45px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  transition: border-color 0.2s, box-shadow 0.2s;
  &::placeholder {
    color: #fff;
  }
  &:focus {
    outline: none;
    border-color: #fff; /* White border on focus */
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Subtle white shadow on focus */
  }
  @media (max-width: 480px) {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
`;
const wrapperStyle = css`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const resendOtpStyle = css`
  margin-top: 10px;
  display: flex;
  width: 100%;
  padding-right: 84px;
  justify-content: flex-end;
  a {
    color: white;
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default VerifyOtp;
